<template>
    <div>
        <div>
            <v-btn class="mr-4" color="secondary" min-width="0" outlined text small href="/scale" title="回量表選單"
                value="回量表選單" aria-label="回量表選單">
                <v-icon>mdi-arrow-left</v-icon>回量表選單
            </v-btn>
        </div>
        <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">評量結果</h2>
        </div>
        <div v-if="type == '2mapping'" class="main-content no-gutters justify-center mt-4" :class="`type-${type}`">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 align-center justify-center row no-gutters text-center mb-4">
                    <div>
                        <h3>{{ totalScoreTitle[0] }}：<span>{{ riskName(0) }}</span></h3>
                        <div class="total-score my-4">
                            <img v-if="lightImg" :src="lightImg[0]" />
                            <span>{{ totalScore[0] }}</span>
                        </div>
                        <p>建議進行心腦操練：{{ totalScoreSubTitle[0] }}</p>
                        <p>{{ totalScoreDescript[0] }}</p>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 align-center justify-center row no-gutters text-center mb-4">
                    <div>
                        <h3>{{ totalScoreTitle[1] }}：<span>{{ riskName(1) }}</span></h3>
                        <div class="total-score my-4">
                            <img v-if="lightImg" :src="lightImg[1]" />
                            <span>{{ totalScore[1] }}</span>
                        </div>
                        <p>建議進行心腦操練：{{ totalScoreSubTitle[1] }}</p>
                        <p>{{ totalScoreDescript[1] }}</p>
                    </div>
                </div>
            </div>
            <div>
                <table class="mx-auto">
                    <tr>
                        <th class="cross" style="border-radius: 15px 0 0 0;">
                            <div class="right">
                                <div>幸福感</div>
                            </div>
                            <div class="left">
                                <div>壓力反應</div>
                            </div>
                        </th>
                        <th>
                            低
                        </th>
                        <th>
                            中
                        </th>
                        <th>
                            高
                        </th>
                        <th style="border-radius: 0 15px 0 0;">
                            <div class="break-line">
                                <div>評估建議</div>
                                <div>心腦操練</div>
                            </div>
                        </th>
                    </tr>
                    <tr class="text-center" v-for="(items, ind) in tableValue" :key="`d${ind}`">
                        <th>{{ items[0] }}</th>
                        <td :class="items[1] == (parseInt(scoreId[0]) + parseInt(scoreId[1])) ? 'selected' : ''">
                            <span>{{ items[1] }}</span></td>
                        <td :class="items[2] == (parseInt(scoreId[0]) + parseInt(scoreId[1])) ? 'selected' : ''">
                            <span>{{ items[2] }}</span></td>
                        <td :class="items[3] == (parseInt(scoreId[0]) + parseInt(scoreId[1])) ? 'selected' : ''">
                            <span>{{ items[3] }}</span></td>
                        <th :class="scoreId[1] == (ind + 1) ? 'selected' : ''"><span>{{ spiritualHappiness.happiness[ind].title
                        }}</span></th>
                    </tr>
                    <tr>
                        <th style="border-radius: 0 0 0 15px;">
                            <div class="break-line">
                                <div>評估建議</div>
                                <div>心腦操練</div>
                            </div>
                        </th>
                        <th :class="scoreId[0] == (ind * 3) ? 'selected' : ''"
                            :style="ind == (item.length - 1) ? ' border-radius: 0 0 15px 0;' : ''"
                            v-for="(item, ind) in spiritualHappiness.stress" :key="item.id">
                            <span>{{ item.title }}</span>
                        </th>
                        <th>

                        </th>
                    </tr>
                </table>
            </div>
        </div>
        <div v-else class="main-content row no-gutters justify-center mt-4">
            <div class="col-xs-12 col-sm-6 col-lg-6 align-center justify-center row no-gutters text-center">
                <div>
                    <h3>{{ totalScoreTitle[0] }}</h3>
                    <div class="total-score my-4">
                        <img v-if="lightImg" :src="lightImg[0]" />
                        <span>{{ totalScore[0] }}</span>
                    </div>
                    <p>{{ totalScoreDescript[0] }}</p>
                </div>
            </div>
            <div v-if="loaded" class="col-xs-12 col-sm-6 col-md-6 align-center justify-center d-flex">
                <BarChart v-if="loaded" :chart-data="chartData" />
            </div>
        </div>
    </div>
</template>


<script>
import BarChart from '@/components/Bar.vue'
import { quizServices } from "@/services/quiz.js";

export default {
    name: "QuizResult",
    components: { BarChart },
    data() {
        return {
            type: '',
            totalScoreTitle: [],
            totalScoreSubTitle: [],
            totalScoreDescript: [],
            totalScoreRisk: [],
            lightImg: [],
            totalScore: [],
            scoreId: [],
            loaded: false,
            chartData: {},
            tableHeader: ['幸福感', '低', '中', '高'],
            tableValue: [
                ['高', '1', '4', '7'],
                ['中', '2', '5', '8'],
                ['低', '3', '6', '9'],
            ],
            spiritualHappiness: {
                happiness: [
                    { id: '0', title: '保養頭腦' },
                    { id: '3', title: '充沛腦力' },
                    { id: '6', title: '善用頭腦' },
                ],
                stress: [
                    { id: '1', title: '肯定自我' },
                    { id: '2', title: '豐富自我' },
                    { id: '3', title: '精神樂活' },
                ]
            },

        }
    },
    async mounted() {

        this.loaded = false;
        let uuid = this.$route.params.uuid;
        const returnData = await quizServices.getQuizResult(uuid);

        let quizData = returnData.data;
        let results = quizData.result;

        this.$store.commit("setQuizTitle", quizData.title);
        //this.$root.$emit('scaleTitle', quizData.title);
        sessionStorage.removeItem('quiz_id');
        sessionStorage.removeItem('user_id');

        const lightImgMap = {
            red: 'light_r.png',
            yellow: 'light_y.png',
            green: 'light_g.png',
        }
        this.type = quizData.type;
        if (quizData.type == 'trueFalse') {
            let result = results['total'];
            this.totalScoreTitle.push(result.title);
            this.totalScoreDescript.push(result.description);
            this.totalScore = result.score;
            let risk = (result.score == 1 ? 'red' : 'green')
            this.lightImg.push(require('@/assets/img/' + lightImgMap[risk]));
        } else if (quizData.type == '2mapping') {
            let result = results['happiness'];
            this.totalScoreTitle.push(result.title);
            this.totalScoreSubTitle.push(result.subtitle);
            this.totalScoreDescript.push(result.description);
            this.totalScore.push(result.score);
            this.totalScoreRisk.push(result.risk);
            this.scoreId.push(result.id);
            this.lightImg.push(require('@/assets/img/' + lightImgMap[result.risk]));
            result = results['stress'];
            this.totalScoreTitle.push(result.title);
            this.totalScoreSubTitle.push(result.subtitle);
            this.totalScoreDescript.push(result.description);
            this.totalScore.push(result.score);
            this.totalScoreRisk.push(result.risk);
            this.scoreId.push(result.id);
            this.lightImg.push(require('@/assets/img/' + lightImgMap[result.risk]));
        } else {
            let labels = [];
            let resultDescript = [];
            let bgColors = [];
            let resultData = [];

            const bgColorMap = {
                red: '#eea4a2',
                yellow: '#f6d9a7',
                green: '#b0ebbd',
            }

            let isOnlyTotal = true;
            for (let key in results) {
                let result = results[key];
                if (key == 'total') {
                    this.totalScoreTitle.push(result.title);
                    this.totalScoreDescript.push(result.description);
                    this.totalScore.push(result.score);
                    this.lightImg.push(require('@/assets/img/' + lightImgMap[result.risk]));
                } else {
                    labels.push(result.title);
                    resultDescript.push(result.description);
                    bgColors.push(bgColorMap[result.risk]);
                    resultData.push(result.score);
                    isOnlyTotal = false;
                }
            }
            if (!isOnlyTotal) {
                let chartData = {
                    labels: labels,
                    datasets: [
                        {
                            labels: resultDescript,
                            backgroundColor: bgColors,
                            data: resultData
                        }
                    ]
                }
                this.chartData = chartData;
                this.loaded = true;
            }
        }
    },
    watch: {
        
    },
    methods: {
        riskName(index) {
            if (this.totalScoreRisk[index] == 'red') {
                if (index == 0) {
                    return '低';
                }
                return '高';
            } else if (this.totalScoreRisk[index] == 'yellow') {
                return '中';
            }
            if (index == 0) {
                return '高';
            }
            return '低';
            
        },
    },
};
</script>

<style scoped>
.total-score {
    position: relative;
    text-align: center;
}

.total-score>img {
    max-width: 200px !important;
    width: 100%;
}

.total-score>span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -16%);
    left: 50%;
    font-size: 4rem;
    color: #fff;
}

.main-content.type-2mapping {
    max-width: 800px;
}

.break-line {
    white-space: pre;
}

table {
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 10px;
    font-size: .85rem;
}

tr:first-child>th {
    background-color: #feeed1;
}

tr:first-child>th:last-child>div {
    border-radius: 10px;
    background-color: #fdd97f;
}

tr:last-child>th:first-child>div {
    border-radius: 10px;
    background-color: #C4D0EA;
}

th,
td {
    width: 70px;
    height: 50px;
}

th:first-child {
    background-color: #E5EAF6;
}

th.cross {
    position: relative;
}

th.cross .right {
    position: absolute;
    top: 0;
    left: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 70px 50px 0;
    border-color: transparent #fdd97f transparent transparent;
    transform: rotate(0deg);
    text-align: right;
    border-radius: 10px;
}

th.cross .right>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    padding-right: 5px;
}

th.cross .left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 50px 0 0 70px;
    border-color: transparent transparent transparent #C4D0EA;
    transform: rotate(0deg);
    border-radius: 10px;
}

th.cross .left>div {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70px;
    text-align: left;
    padding-left: 5px;
}

td.selected>span {
    padding: 5px 10px;
    border-radius: 50%;
    color: #fff;
}

th.selected>span {
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
}

td.selected>span,
th.selected>span {
    background-color: #8baa1b;
    font-size: .7rem;
}

@media (min-width: 600px) {

    table {
        font-size: unset;
    }
    th,
    td {
        width: 120px;
    }

    th.cross .right {
        border-width: 0 120px 50px 0;
    }

    th.cross .right>div {
        width: 120px;
        padding-right: 10px;
        padding-top: 2px;
    }

    th.cross .left {
        border-width: 50px 0 0 120px;
    }

    th.cross .left>div {
        width: 120px;
        padding-left: 10px;
        padding-bottom: 2px;
    }
    td.selected>span,
    th.selected>span {
        font-size: unset;
    }
}

@media (min-width: 960px) {

th,
td {
    width: 150px;
    height: 50px;
}

th.cross .right {
    border-width: 0 150px 50px 0;
}

th.cross .right>div {
    width: 150px;
}

th.cross .left {
    border-width: 50px 0 0 150px;
}

th.cross .left>div {
    width: 150px;
}
}
</style>